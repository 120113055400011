
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import React from 'react';
import { useEffect } from 'react';
import { Toaster } from 'sonner';
import type { AppProps } from 'next/app';
import type { Session } from 'next-auth';
import { SessionProvider } from 'next-auth/react';
import { usePersistLocaleCookie } from 'i18n/hooks';
import { CookiesProvider } from 'react-cookie';
import GTM from 'react-gtm-module';
import { Provider as ReduxProvider } from 'react-redux';
import { store } from 'redux/store';
import { CookieBanner } from 'modules/common/components/CookieBanner';
import { MediaContextProvider } from 'modules/common/components/Media';
import '../styles/ItemPhoto.css';
import '../styles/globals.css';
import '../styles/tailwind.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { fontVariables } from 'ui/fonts/fontVariables';
export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});
function RevibeConsumerFrontend({ Component, pageProps: { session, ...pageProps }, }: AppProps<{
    session: Session;
}>) {
    useEffect(() => {
        GTM.initialize({
            gtmId: process.env.NEXT_PUBLIC_GTM_ID as string,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    usePersistLocaleCookie();
    return (<div suppressHydrationWarning className={fontVariables}>
      <QueryClientProvider client={queryClient}>
        <SessionProvider session={session}>
          <CookiesProvider>
            {/* @ts-ignore */}
            <MediaContextProvider>
              <ReduxProvider store={store}>
                <Component suppressHydrationWarning {...pageProps}/>
                <CookieBanner />
                <Toaster position="bottom-center" richColors closeButton/>
                <ReactQueryDevtools position="bottom-right" initialIsOpen={false}/>
              </ReduxProvider>
            </MediaContextProvider>
          </CookiesProvider>
        </SessionProvider>
      </QueryClientProvider>
    </div>);
}
const __Next_Translate__Page__18c6845d60b__ = RevibeConsumerFrontend;

    export default __appWithI18n(__Next_Translate__Page__18c6845d60b__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
    });
  